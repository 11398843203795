<template>
    <div id="pix">
        <div class="row" v-if="!pixPago">
            <div class="col-md-12 text-center d-flex flex-column">
                <span class="mt-3 fs-5">
                    {{
                        $route.query.acao == 'plano_expirado' 
                        ? 'Após o pagamento ser concluído seu plano será renovado para mais <b>30 dias!</b>' 
                        : 'Após o pagamento ser concluído seu plano será ativado!' 
                    }}
                </span>

                <Message>Não feche esta página</Message>
            </div>

            <div class="col-md-12 text-center d-flex flex-column justify-content-center align-items-center mt-4">
                <img :src="pix.qrCodeImage" alt="qrCode" style="width: 200px">
            </div>

            <div class="col-md-12 text-center mt-3">
                <p>Tempo restante</p>
                <h4>{{ tempoRestante }}</h4>
                <ProgressBar mode="indeterminate" style="height: 6px; margin-top: 15px"></ProgressBar>
            </div>

            <div class="mt-3 col-md-12 text-center d-flex flex-column">
                <InputText v-model="pix.copiaEcola" readonly class="w-full" />
                <Button label="Copiar código" class="rounded mt-3" outlined @click="copy" />
            </div>
            <Toast />
        </div>
    </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Button from 'primevue/button';
import Toast from 'primevue/toast';

export default {
    props: ['pix', 'usuario'],
    components: {
        ProgressBar,
        InputText,
        Button,
        Toast,
        Message,
    },
    data() {
        return {
            tempoRestante: "",
            contador: null,
            tempoExpiracaoOriginal: 900,
        }
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.$props.pix.copiaEcola);
            this.$toast.add({ severity: 'success', summary: 'Copiado', detail: 'Código pix copiado com sucesso!', life: 3000 });
        },
        iniciarContagemRegressiva() {
            this.contador = setInterval(() => {
                this.atualizarContagemRegressiva();
            }, 1000);
        },
        formatarTempo(segundos) {
            const minutos = Math.floor(segundos / 60);
            const segundosRestantes = segundos % 60;
            return `${minutos}:${segundosRestantes < 10 ? "0" : ""}${segundosRestantes}`;
        },
        atualizarContagemRegressiva() {
            this.tempoExpiracaoOriginal--;

            if (this.tempoExpiracaoOriginal > 0) {
                this.tempoRestante = this.formatarTempo(this.tempoExpiracaoOriginal);
            } else {
                clearInterval(this.contador);
                this.tempoRestante = "Expirado";
            }
        },
        verificaPixPago() {
            fetch(`https://projetopix.lksoftware.com.br/public/api/buscar-pix/${this.$props.pix.identifier}`).then(response => {
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                return response.json();
            }).then(data => {
                if (data.charges != undefined && data.charges[0].status == 'PAID') {
                    this.$emit('pixPago', true);
                }
            }).catch(error => {
                console.error('Fetch error:', error);
            });
        },
    },
    mounted() {
        setInterval(() => {
            this.verificaPixPago();
        }, 20000);

        this.iniciarContagemRegressiva();
    },
    beforeDestroy() {
        clearInterval(this.contador);
    },
}
</script>

<style>

</style>