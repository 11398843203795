import { createRouter, createWebHistory } from 'vue-router'
import { authMiddleware } from '@/assets/js/auth';

const routes = [
  {
    path: '/',
    name: 'login',
    component: import('../views/Login.vue')
  },
  {
    path: '/politicas-privacidade',
    name: 'politicas-privacidade',
    component: import('../views/PoliticasPrivacidade.vue'),
  },
  {
    path: '/termos-uso',
    name: 'termos-uso',
    component: import('../views/TermosUso.vue'),
  },
  {
    path: '/renovar-plano',
    name: 'renovar-plano',
    component: import('../views/usuarios/RenovarPlano.vue'),
  },
  {
    path: '/alterar-senha',
    name: 'alterar-senha',
    component: import('../views/usuarios/RenovarSenha.vue')
  },
  {
    path: '/pacientes',
    name: 'pacientes',
    component: () => import('../views/Pacientes.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/cadastro',
    name: 'cadastro-paciente',
    component: () => import('../views/pacientes/CadastroPaciente.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/editar/:id',
    name: 'editar-paciente',
    component: () => import('../views/pacientes/EditarPaciente.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/prontuario/:id',
    name: 'prontuario',
    component: () => import('../views/Prontuario.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/sugestoes',
    name: 'sugestoes',
    component: () => import('../views/Sugestoes.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/agendamentos',
    name: 'agendamentos',
    component: () => import('../views/Agendamentos.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('../views/financeiro/Financeiro.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../views/Perfil.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/novidades',
    name: 'novidades',
    component: () => import('../views/Novidades.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/chamados',
    name: 'chamados',
    component: () => import('../views/Chamados.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/notificacoes',
    name: 'notificacoes',
    component: () => import('../views/Notificacoes.vue'),
    beforeEnter: authMiddleware,
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('../views/PageNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
