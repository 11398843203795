<template>
    <div id="validacao-duas-etapas">
        <div class="card flex justify-content-center">
            <div class="col-md-12 text-center mt-3 p-3">
                Digite o código de 6 dígitos que enviamos para seu email de cadastro
            </div>

            <InputOtp v-model="codigo" :length="6">
                <template #default="{ attrs, events }">
                    <input type="text" v-bind="attrs" v-on="events" class="custom-otp-input" />
                </template>
            </InputOtp>

            <div class="col-md-12 d-flex p-2">
                <Button v-if="mostrarBotao" severity="help" class="mt-3 mb-3 w-full rounded d-flex justify-content-center" @click="validarCodigo" outlined >
                    <ProgressSpinner v-if="isLoading" style="width: 20px; height: 20px" :disabled="isLoading" />
                    <span v-else>Login</span>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import InputOtp from 'primevue/inputotp';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';

export default {
    props: ['dadosUsuario'],
    components: {
        InputOtp,
        ProgressSpinner,
        Button,
    },
    data() {
        return {
            isLoading: false,
            codigo: '',
            mostrarBotao: false,
        }
    },
    methods: {
        validarCodigo() {
            this.api.get(`/validar-codigo/${this.codigo}`).then((res) => {
                if (res.status == 200) {
                    this.login();
                }
            }).catch((err) => {
                this.$toast.add({
                    severity: "error",
                    summary: "Erro!",
                    detail: err.response.data.error,
                    life: 3000,
                });
            }).finally(() => {});
        },
        login() {
            this.isLoading = true;

            this.api.post("/login", this.$props.dadosUsuario).then((res) => {
                if (res.status == 200) {
                    sessionStorage.setItem("usuario", JSON.stringify(res.data));
                    sessionStorage.setItem('mostraJanelaPin', true);
                    this.$router.push("/novidades");
                }
            }).catch((err) => {
                this.$toast.add({
                    severity: "error",
                    summary: "Erro!",
                    detail: err.response.data.error,
                    life: 3000,
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
    watch: {
        codigo(value) {
            if (value.length == 6) {
                this.mostrarBotao = true;
            }
        }
    }
}
</script>

<style>

.custom-otp-input {
    width: 40px;
    font-size: 36px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid var(--surface-500);
}

.custom-otp-input:focus {
    outline: 0 none;
    border-bottom-color: var(--primary-color);
}

.p-inputotp.p-component {
    justify-content: center;
    margin: 20px;
}

</style>