<template>
    <div id="pin">
      <div class="modal fade" id="modalPin" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="modalPinLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalPinLabel">Digite seu novo PIN de autenticação</h1>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                <ol>
                  <li>
                    Após digitar o PIN aguarde a validação
                  </li>
                  <li>
                    Caso ainda não tenha criado seu PIN, basta clicar no botão azul e criar
                  </li>
                </ol>
              </div>
              <div class="col-md-12 mt-3">
                <input type="text" v-model="pin" class="form-control" placeholder="000000" v-mask="'######'">
              </div>
            </div>
            <div class="modal-footer">

              <div v-if="!usuarioLogado.hasPin && !pinRecemCriado">
                <Button severity="help" class="rounded d-flex justify-content-center" outlined data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">
                  <ProgressSpinner v-if="loadingCriarPin" style="width: 20px; height: 20px" :disabled="loadingCriarPin" />

                  <span v-else>Criar PIN</span>
                </Button>
              </div>

              <div v-else>
                <Button severity="warning" class="rounded d-flex justify-content-center" outlined data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" @click="alterarPin">
                  <ProgressSpinner v-if="loadingAlterarPin" style="width: 20px; height: 20px" :disabled="loadingAlterarPin" />

                  <span v-else>Alterar PIN</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="exampleModalToggle2" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">PIN</h1>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                <div class="col-md-12">
                  <ol>
                    <li>
                      Deve conter apenas 6 digitos
                    </li>
                    <li>
                      Deve ser apenas números
                    </li>
                  </ol>
                </div>
                <input type="text" v-model="create_pin" class="form-control mt-3" placeholder="000000" v-mask="'######'">
              </div>
            </div>
            <div class="modal-footer">
              <Button label="Voltar" class="rounded d-flex justify-content-center btn-voltar-pin" severity="secondary" data-bs-target="#modalPin" data-bs-toggle="modal" raised />

              <Button severity="help" class="rounded d-flex justify-content-center" @click="criarPin" outlined >
                <ProgressSpinner v-if="loadingCriarPin" style="width: 20px; height: 20px" :disabled="loadingCriarPin" />

                <span v-else>Salvar PIN</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

    <Toast />
    </div>
  
</template>

<script>
import InputOtp from 'primevue/inputotp';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    props: ['fezLogout'],
    components: {
        InputOtp,
        Toast,
        Button,
        ProgressSpinner,
    },
    data() {
      return {
        pin: '',
        create_pin: '',
        messagePinCriado: '',
        loadingCriarPin: false,
        loadingAlterarPin: false,
        pinRecemCriado: false,
        usuarioLogado: JSON.parse(sessionStorage.getItem('usuario')).usuario,
      }
    },
    methods: {
      criarPin() {
        const alterarPin = sessionStorage.getItem('alterarPin');
        
        this.loadingCriarPin = true;

        this.api.put(`usuario/${this.usuarioLogado.id}/pin/${this.create_pin}`, {alterarPin: alterarPin}).then(res => {
          if (res.status == 200) {
            this.pinRecemCriado = true;
            document.querySelector('.btn-voltar-pin').click();
            this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: res.data.success, life: 3000 });
          }
        }).catch(err => {
          this.$toast.add({
              severity: "error",
              summary: "Erro!",
              detail: err.response.data.error,
              life: 3000,
          });
        }).finally(() => {
          this.loadingCriarPin = false;
        })
      },
      alterarPin() {
        sessionStorage.setItem('alterarPin', 1)
      },
      validarPin() {
          this.api.get(`/validar-pin/${this.pin}/usuario/${this.usuarioLogado.id}`).then(res => {
              if (res.status == 200) {
                sessionStorage.setItem('sessionTime', 1800)
                this.pin = '';
                this.closeModalPin();
                this.session();
              }
          }).catch(err => {
              this.$toast.add({
                  severity: "error",
                  summary: "Erro!",
                  detail: err.response.data.error,
                  life: 3000,
              });
          });
      },
      closeModalPin() {
          const myModalEl = $('#modalPin');
          const backdrop = $('.modal-backdrop');
  
          if (myModalEl.hasClass('show')) {
              myModalEl.modal('hide');
              backdrop.remove();
          }
      },
      session() {
        // Inicia o intervalo para diminuir 1 segundo a cada 1 segundo
        this.interval = setInterval(() => {
          // Obtém o valor atual do 'sessionTime' no sessionStorage
          let sessionTime = parseInt(sessionStorage.getItem('sessionTime'));

          // Verifica se o tempo ainda não acabou
          if (sessionTime > 0) {
            // Diminui 1 segundo do tempo
            sessionTime--;

            // Atualiza o 'sessionTime' no sessionStorage com o novo valor
            sessionStorage.setItem('sessionTime', sessionTime);
            this.$emit('updateSessionTime', sessionTime);

            if (this.$props.fezLogout) {
              clearInterval(this.interval);
            }
          } else {
            this.showPin()
            // Caso o tempo tenha acabado, limpa o intervalo para parar a contagem regressiva
            clearInterval(this.interval);
          }
        }, 1000);
      },
      showPin() {
        const myModalEl = $('#modalPin');

        if (!myModalEl.hasClass('show')) {
            myModalEl.modal('show');
        }
      }
    },
    created() {
      this.session();
    },
    destroyed() {
      this.session();
    },
    watch: {
      pin(value) {
        if (value.length == 6) {
          this.validarPin();
        }
      }
    },
}
</script>

<style>

</style>