export function authMiddleware(to, from, next) {
    const usuarioAutenticado = sessionStorage.getItem('usuario') !== null;

    const user = JSON.parse(sessionStorage.getItem('usuario'));
    
    if (user!= null && user.tipo_usuario == 'afiliado' && to.path !== '/perfil') {
        next('/perfil');
    } else if (!usuarioAutenticado && to.path !== '/') {
        next('/');
    } else {
        next();
    }
}