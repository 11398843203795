<template>
   <div class="container mt-5">
      <div class="row justify-content-center">
         <div class="col-md-6">
            <div class="card shadow-lg">
               <div class="card-body">
                  <div class="col-md-12 text-center p-3 mb-4">
                     <img
                        :src="require('@/assets/img/logo3_semfundo.png')"
                        style="width: 200px"
                        alt="logo"
                     />
                  </div>
                  <form id="loginForm" v-if="!mostrarTelaVerificacao && !renovar_plano && !renovar_senha">
                     <div class="flex flex-wrap gap-3 mb-3 justify-content-center">
                        <div class="flex align-items-center">
                           <RadioButton v-model="tipo_login" inputId="telefone" name="tipo_login" value="telefone" />
                           <label for="telefone" class="ml-2">Telefone</label>
                        </div>
                        <div class="flex align-items-center">
                           <RadioButton v-model="tipo_login" inputId="ingredient2" name="tipo_login" value="email" />
                           <label for="ingredient2" class="ml-2">Email</label>
                        </div>
                     </div>

                     <div class="mb-3">
                        <label for="email" class="form-label">{{ tipo_login === 'telefone' ? 'Telefone' : 'Email' }}</label>
                        <div v-if="tipo_login === 'email'">
                           <InputText type="text" v-model="dadosUsuario.email" class="w-full" placeholder="exemplo@email.com" />
                        </div>
                        <div v-else>
                           <InputText type="text" v-model="dadosUsuario.telefone" v-mask="'(##) #####-####'" class="w-full" placeholder="(00) 00000-0000" />
                        </div>

                     </div>

                     <div class="mb-3">
                        <label for="password" class="form-label">Senha</label>
                        <Password v-model="dadosUsuario.password" toggleMask placeholder="Senha" class="w-full" />
                     </div>

                     <div class="mb-3 cursor-pointer col-md-12">
                        <span>Ainda não possui cadastro?</span> <span class="text-primary" @click="cadastrar">clique aqui!</span>
                     </div>

                     <div class="mb-3 cursor-pointer col-md-12">
                        <span>Esqueceu sua senha?</span> <span class="text-primary" @click="$router.push('/alterar-senha')">clique aqui!</span>
                     </div>

                     <Button severity="help" class="w-full rounded d-flex justify-content-center" @click="login" outlined >
                        <ProgressSpinner v-if="isLoading" style="width: 20px; height: 20px" :disabled="isLoading" />
                        <span v-else>Login</span>
                     </Button>
                  </form>
               </div>
            </div>

            <Toast />
         </div>
      </div>
   </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ValidacaoDuasEtapas from './login/ValidacaoDuasEtapas.vue';
import RenovarPlano from './usuarios/RenovarPlano.vue';
import RenovarSenha from './usuarios/RenovarSenha.vue';

export default {
   components: {
      RadioButton,
      Button,
      ProgressSpinner,
      Password,
      InputText,
      Toast,
      ValidacaoDuasEtapas,
      RenovarPlano,
      RenovarSenha,
   },
   data() {
      return {
         dadosUsuario: {
            email: "",
            telefone: "",
            password: "",
         },
         tipo_login: "telefone",
         isLoading: false,
         mostrarTelaVerificacao: false,
         renovar_plano: false,
         renovar_senha: false,
      };
   },
   methods: {
      login() {
         this.isLoading = true;

         this.api.post("/login", this.dadosUsuario).then((res) => {
               if (res.status == 200) {
                  sessionStorage.setItem("usuario", JSON.stringify(res.data));
                  sessionStorage.setItem('sessionTime', 1800)
                  sessionStorage.setItem('isAutenticated', true)
                  
                  this.$emit('usuario', res.data.usuario);

                  if (res.data.usuario.tipo_usuario == 'psicologo') {
                     this.$router.push("/novidades");
                  } else {
                     this.$router.push("/perfil");
                  }
               }
         }).catch((err) => {
               if (err.response.data.acao) {
                  this.$router.push(`/renovar-plano?email=${this.dadosUsuario.email}&telefone=${this.dadosUsuario.telefone}&acao=${err.response.data.acao}`);
               }
               
               this.$toast.add({
                  severity: "error",
                  summary: "Erro!",
                  detail: err.response.data.error,
                  life: 3000,
               });
         }).finally(() => {
               this.isLoading = false;
         });
      },
      obterInformacoesDispositivo() {
         const sistemaOperacional = this.obterSistemaOperacional();
         const tipoSistema = this.obterTipoDispositivo();

         return {
            sistemaOperacional,
            tipoSistema
         };
      },
      obterSistemaOperacional() {
         const userAgent = navigator.userAgent;

         if (userAgent.match(/Windows/i)) {
            return 'Windows';
         } else if (userAgent.match(/Macintosh|Mac OS/i)) {
            return 'Mac OS';
         } else if (userAgent.match(/Android/i)) {
            return 'Android';
         } else if (userAgent.match(/iOS/i)) {
            return 'iOS';
         } else if (userAgent.match(/Linux/i)) {
            return 'Linux';
         } else {
            return 'Desconhecido';
         }
      },
      obterTipoDispositivo() {
         const userAgent = navigator.userAgent;

         if (userAgent.match(/Mobile/i)) {
            return 'Celular';
         } else if (userAgent.match(/Tablet/i)) {
            return 'Tablet';
         } else {
            return 'Computador';
         }
      },
      cadastrar() {
         window.open('https://psicoprontuarios.com.br/cadastro', '_blank');
      },
   },
   mounted() {
      if (this.$route.query.plano) {
         this.$toast.add({ severity: 'success', summary: 'Sucesso!', detail: 'Seu plano foi renovado com sucesso!', life: 3000 });
      }

      if (this.$route.query.senha_alterada) {
         this.$toast.add({ severity: 'success', summary: 'Sucesso!', detail: 'Sua senha foi alterada com sucesso!', life: 3000 });
      }

      const usuario = JSON.parse(sessionStorage.getItem('usuario'));

      if (usuario !== null) {
         this.$router.push('/novidades')
      }
   }
};
</script>

<style>
</style>
