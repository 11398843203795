<template>
            
  <n-loading-bar-provider>
  <n-message-provider>
      <n-notification-provider>
      <n-modal-provider>
          <n-dialog-provider>
            <div>
              <div v-if="$route.name == 'login'">
                <Login @usuario="usuario" />
              </div>

              <div v-else-if="$route.name == 'politicas-privacidade'">
                <PoliticasPrivacidade />
              </div>

              <div v-else-if="$route.name == 'termos-uso'">
                <TermosUso />
              </div>

              <div v-else-if="$route.name == 'alterar-senha'">
                <RenovarSenha />
              </div>

              <div v-else-if="$route.name == 'renovar-plano'">
                <RenovarPlano />
              </div>

              <div class="d-flex" id="wrapper" v-else>
                <Sidebar :userData="userData" />
                <div id="page-content-wrapper">
                  <Navbar />
                  <router-view />
                </div>
                <SpeedInsights />
              </div>
          </div>
          </n-dialog-provider>
      </n-modal-provider>
      </n-notification-provider>
  </n-message-provider>
  </n-loading-bar-provider>
</template>

<script>
import Login from '@/views/Login.vue';
import RenovarSenha from './views/usuarios/RenovarSenha.vue';
import RenovarPlano from './views/usuarios/RenovarPlano.vue';
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';
import { SpeedInsights } from "@vercel/speed-insights/vue"
import PoliticasPrivacidade from './views/PoliticasPrivacidade.vue';
import TermosUso from './views/TermosUso.vue';

export default {  
  components: {
    Login,
    Navbar,
    Sidebar,
    RenovarSenha,
    SpeedInsights,
    RenovarPlano,
    PoliticasPrivacidade,
    TermosUso
},
  data() {
    return {
      userData: null,
    }
  },
  methods: {
    usuario(value) {
      this.userData = value;
    }
  }
}
</script>

<style>
#app {
  height: 100vh;
}

ul {
  padding-left: 0 !important;
}

.p-toast {
  z-index: 10000 !important;
}

.p-tabview-nav-link {
  text-decoration: none !important;
  color: #D1C1FF;
}

.p-tabview-nav-link:hover {
  color: #D1C1FF;
}

a {
  text-decoration: none !important;
  color: #65529d !important;
}

hr {
  margin: 5px !important;
}

.p-dropdown-panel.p-component.p-ripple-disabled {
  z-index: 10000 !important;
}

.p-menuitem > a > span {
  color: #65529d !important;
}

.icon-purple {
  color: #65529d !important;
}

.p-datepicker.p-component.p-ripple-disabled {
    z-index: 10000 !important;
}

.p-inputtext.p-component.p-password-input {
   width: 100% !important;
}
</style>
