<template>
    <div class="container mt-5" id="renovar-senha">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="col-md-12 text-center p-3 mb-4">
                            <img
                                :src="require('@/assets/img/logo3_semfundo.png')"
                                style="width: 200px"
                                alt="logo"
                            />
                        </div>

                        <div class="col-md-12" v-if="!codigoEnviado && !codigoConfirmado">
                            <div class="mb-3">
                                <label for="email" class="form-label">Digite seu email</label>
                                <InputText type="email" v-model="usuario.email" class="w-full" placeholder="exemplo@email.com" />
                            </div>

                            <Button severity="help" class="w-full rounded d-flex justify-content-center" @click="enviarCodigoDeVerificacao" outlined >
                                <ProgressSpinner v-if="isLoading" style="width: 20px; height: 20px" :disabled="isLoading" />
                                <span v-else>Enviar código</span>
                            </Button>

                            <Button severity="secondary" label="Voltar" class="w-full rounded mt-3" @click="$router.push('/')" raised />
                        </div>

                        <div class="row" v-if="codigoEnviado && !codigoConfirmado">
                            <div class="card flex justify-content-center">
                                <div class="col-md-12 text-center mt-3 p-3">
                                    Digite o código de 6 dígitos que enviamos para seu email de cadastro
                                </div>

                                <InputOtp v-model="codigo" :length="6">
                                    <template #default="{ attrs, events }">
                                        <input type="text" v-bind="attrs" v-on="events" class="custom-otp-input" />
                                    </template>
                                </InputOtp>

                                <div class="col-md-12 d-flex p-2">
                                    <Button v-if="mostrarBotao" severity="help" class="mt-3 mb-3 w-full rounded d-flex justify-content-center" @click="validarCodigo" outlined >
                                        <ProgressSpinner v-if="isLoadingValidarCodigo" style="width: 20px; height: 20px" :disabled="isLoadingValidarCodigo" />
                                        <span v-else>Validar Código</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="codigoConfirmado">
                            <div class="mb-3">
                                <label for="password" class="form-label">Digite sua nova senha</label>
                                <Password :invalid="senhasDiferentes" v-model="usuario.password" toggleMask placeholder="Senha" class="w-full" />
                                <span class="text-sm text-danger" v-if="senhasDiferentes">As senhas devem ser iguais</span>
                            </div>

                            <div class="mb-3">
                                <label for="password" class="form-label">Confirmar Senha</label>
                                <Password :invalid="senhasDiferentes" v-model="usuario.confirm_password" toggleMask placeholder="Confirmar Senha" class="w-full" @input="confirmarSenhasIguais" />
                                <span class="text-sm text-danger" v-if="senhasDiferentes">As senhas devem ser iguais</span>
                            </div>

                            <Button severity="help" class="w-full rounded d-flex justify-content-center" @click="alterarSenha" outlined >
                                <ProgressSpinner v-if="isLoadingAlterarSenha" style="width: 20px; height: 20px" :disabled="isLoadingAlterarSenha" />

                                <span v-else>Resetar senha</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Toast />
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Password from 'primevue/password';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import InputOtp from 'primevue/inputotp';
import Toast from 'primevue/toast';

export default {
    components: {
        InputText,
        Password,
        Button,
        ProgressSpinner,
        InputOtp,
        Toast,
    },
    data() {
        return {
            usuario: {
                password: '',
                confirm_password: '',
                email: '',
            },
            codigo: '',
            mostrarBotao: false,
            codigoEnviado: false,
            codigoConfirmado: false,
            isLoading: false,
            isLoadingValidarCodigo: false,
            isLoadingAlterarSenha: false,
            senhasDiferentes: false,
        }
    },  
    methods: {
        enviarCodigoDeVerificacao() {
            this.isLoading = true;

            this.api.get(`codigo-recuperar-senha/${this.usuario.email}`).then((res) => {
                if (res.status == 200) {
                    this.codigoEnviado = true;
                }
            }).catch((err) => {
                console.log(err);
                // this.$toast.add({
                //     severity: "error",
                //     summary: "Erro!",
                //     detail: err.response.data.error,
                //     life: 3000,
                // });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        validarCodigo() {
            this.isLoadingValidarCodigo = true;
            this.api.get(`validar-codigo/${this.codigo}`).then((res) => {
                if (res.status == 200) {
                    this.codigoEnviado = false;
                    this.codigoConfirmado = true;
                }
            }).catch((err) => {
                this.$toast.add({
                    severity: "error",
                    summary: "Erro!",
                    detail: err.response.data.error,
                    life: 3000,
                });
            }).finally(() => {
                this.isLoadingValidarCodigo = false;
            });
        },
        alterarSenha() {
            this.isLoadingAlterarSenha = true;

            this.api.put(`/alterar-senha`, this.usuario).then((res) => {
                if (res.status == 200) {
                    this.$router.push({path: '/', query: {senha_alterada: true}});
                }
            }).catch((err) => {
                this.$toast.add({
                    severity: "error",
                    summary: "Erro!",
                    detail: err.response.data.error,
                    life: 3000,
                });
            }).finally(() => {
                this.isLoadingAlterarSenha = false;
            });
        },
        confirmarSenhasIguais(input) {
            if (input.target.value != this.usuario.password) {
                this.senhasDiferentes = true;
            } else  {
                
                this.senhasDiferentes = false;
            }
        }
    },
    watch: {
        codigo(value) {
            if (value.length == 6) {
                this.mostrarBotao = true;
            }
        }
    }
}
</script>

<style>

</style>