<template>
    <!-- Top navigation-->
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom navbar-custom">
        <div class="container-fluid">
            <button class="btn-outline-green" id="sidebarToggle">
                <i class="fa-solid fa-bars"></i>
            </button>
            
            <div class="ms-3" :class="{ 'text-danger fa-fade': minutes < 1 }">
                <span class="fw-bold">Tempo da sessão: </span>
                <span>
                    {{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}
                </span>
            </div>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
                    <li class="nav-item active">
                        <button class="btn btn-outline-primary btn-sm" @click="logout">
                            <i class="fa-solid fa-power-off"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        
        <Pin @updateSessionTime="updateSessionTime" :fezLogout="fezLogout" v-if="isAutenticated" />
    </nav>
</template>

<script>
import Pin from '@/components/Pin.vue';

export default {
    components: {
        Pin,
    },
    data() {
        return {
            sessionTime: 0,
            minutes: 0,
            seconds: 0,
            fezLogout: false,
            isAutenticated: false,
        };
    },
    methods: {
        updateSessionTime(time) {
            // Atualiza o tempo
            this.sessionTime = time;
            // Converte o tempo de segundos para minutos e segundos
            this.minutes = Math.floor(this.sessionTime / 60);
            this.seconds = this.sessionTime % 60;
        },
        logout() {
            sessionStorage.removeItem('usuario');
            sessionStorage.removeItem('sessionTime');
            this.fezLogout = true;
            this.$router.push('/');
        }
    },
    mounted() {
        const isAutenticated = sessionStorage.getItem('isAutenticated');

        if (isAutenticated != undefined && isAutenticated) {
            this.isAutenticated = isAutenticated;
        }
    }
}
</script>

<style scoped>

.btn-outline-green {
    outline: none;
    border: 1px solid #65529d;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    background: transparent;
    color: #65529d;
}

.navbar-custom {
    padding: 14px;
}

</style>