import axios from 'axios';

axios.defaults.baseURL = 'https://psicoprontuarios.lksoftware.com.br/public/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

// Adiciona um interceptor para incluir o token de autorização em cada solicitação
axios.interceptors.request.use(
    (config) => {
        const path = config.url.split('/')[0];

        // Verifica se a solicitação está na página de login
        if (config.url.includes('/renovar-plano-vencido') || path === 'codigo-recuperar-senha' || config.url.includes('/alterar-senha') || path === 'validar-codigo' || config.url.includes('/login') || config.url.includes('/verificacao-duas-etapas') || config.url.includes('/verificacao')) {
            // Não adiciona o cabeçalho de autorização para a página de login
            return config;
        }

        const token = JSON.parse(sessionStorage.getItem('usuario')).usuario.token;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const api = {
    get: (rota, dados = null) => axios.get(rota, dados = null),
    post: (rota, dados) => axios.post(rota, dados),
    put: (rota, dados) => axios.put(rota, dados),
    delete: (rota) => axios.delete(rota),
};
