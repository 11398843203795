import { createStore } from 'vuex'

export default createStore({
  state: {
    modalChamado: false
  },
  getters: {
    // Getter para acessar o estado de modalChamado
    getModalChamado(state) {
      return state.modalChamado;
    }
  },
  mutations: {
    SET_MODAL_CHAMADO(state, value) {
      state.modalChamado = value;
    }
  },
  actions: {
    // Action para chamar a mutation SET_MODAL_CHAMADO
    toggleModalChamado({ commit, state }) {
      // Inverte o estado atual de modalChamado
      commit('SET_MODAL_CHAMADO', !state.modalChamado);
    }
  },
  modules: {
  }
})
