<template>
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="col-md-12 text-center p-3 mb-4">
                            <img
                                :src="require('@/assets/img/logo3_semfundo.png')"
                                style="width: 200px"
                                alt="logo"
                            />
                        </div>
                        
                        <div class="row p-3 gap-3" v-if="!pixGerado">
                            <div class="col-md-12 text-center d-flex flex-column">
                                <i class="fa-solid fa-heart-crack fa-2xl text-danger"></i>
                                <span class="mt-3 fs-5 fw-bold">
                                    {{ $route.query.acao == 'plano_expirado' ? 'Infelizmente seu plano expirou' : 'Seu plano esta inativo' }}
                                </span>
                            </div>

                            <div class="col-md-12 text-center d-flex flex-column">
                                <span class="mt-3">
                                    {{ $route.query.acao == 'plano_expirado' ? 'Valor da renovação' : 'Valor para ativar' }}
                                </span>
                                <span class="fs-5 fw-bold">
                                    R$ 29,90
                                </span>
                            </div>
                            
                            <div class="col-md-12 text-center d-flex flex-column">
                                <span class="">Dias</span>
                                <span class="fs-5 fw-bold">
                                    30 dias
                                </span>
                            </div>

                            <Button class="rounded w-full d-flex justify-content-center" outlined @click="gerarQrCode" :disabled="isLoadingPagamento">
                                <ProgressSpinner v-if="isLoadingPagamento" style="width: 20px; height: 20px" />

                                <span v-else>
                                    {{ $route.query.acao == 'plano_expirado' ? 'Clique aqui renovar!' : 'Clique aqui ativar!' }}
                                </span>
                            </Button>
                        </div>

                        <PixRenovarOuAtivarPlano v-if="pixGerado" :pix="pix" :usuario="usuario" @pixPago="pixPago" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from'primevue/button';
import InputText from'primevue/inputtext';
import PixRenovarOuAtivarPlano from './PixRenovarOuAtivarPlano.vue';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    components: {
        Button,
        InputText,
        PixRenovarOuAtivarPlano,
        ProgressSpinner,
    },
    data() {
        return {
            pix: [],
            pixGerado: false,
            isLoadingPagamento: false,
        }
    },
    methods: {
        gerarQrCode() {
            this.isLoadingPagamento = true;
            fetch('https://projetopix.lksoftware.com.br/public/api/novo-pix?valor=29.90').then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response.json();
            }).then(data => {
                this.pix = data;
                this.pixGerado = true;
            })
            .catch(error => {
                console.error('Fetch error:', error);
            }).finally(() => {
                this.isLoadingPagamento = false;
            });
        },
        pixPago(value) {
            if (value) {
                this.dialogPix = false;
                this.atualizarUsuario();
            }
        },
        atualizarUsuario() {
            const usuario = {
                email: this.$route.query.email != undefined ? this.$route.query.email : null,
                telefone: this.$route.query.telefone != undefined ? this.$route.query.telefone : null,
                acao: this.$route.query.acao,
            }

            this.api.put(`/renovar-plano-vencido`, usuario).then(res => {
                if (res.status == 200) {
                    this.$router.push('/?plano=true');
                }
            }).catch(err => {
                console.log(err);
            })
        },
    },
}
</script>

<style>

</style>